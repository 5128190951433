import React from "react";
import Styles from "./styles";
const { Wrapper, NavLink, LangBtn } = Styles;
import IMobileMenu from "./types";
import { useIntl } from "gatsby-plugin-react-intl";

const MobileMenu = ({ isMenuActive, setIsMenuActive }: IMobileMenu) => {
  const intl = useIntl();
  return (
    <Wrapper isMenuActive={isMenuActive} setIsMenuActive={setIsMenuActive}>
      <NavLink to="services" smooth={true} onClick={() => setIsMenuActive()}>
        {intl.formatMessage({ id: "services" })}
      </NavLink>
      <NavLink to="contact" smooth={true} onClick={() => setIsMenuActive()}>
        {intl.formatMessage({ id: "contact" })}
      </NavLink>
      <div style={{ marginTop: "10px" }}>
        <LangBtn to="/pl">PL</LangBtn> / <LangBtn to="/en">EN</LangBtn>
      </div>
    </Wrapper>
  );
};

export default MobileMenu;
