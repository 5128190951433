import React from "react";
import Styles from "./styled";
import { Container, SVGIcon, Text } from "../../../Styled";
import Icons from "../../../images/icons";
import { useIntl } from "gatsby-plugin-react-intl";

const { phone, envelope } = Icons;
const {
  NavWrapper,
  NavContainer,
  NavSide,
  LangBtn,
  NavLink,
  LangLink,
} = Styles;

const Navigation = () => {
  const intl = useIntl();
  return (
    <NavWrapper>
      <Container>
        <NavContainer>
          <address>
            <NavSide>
              <SVGIcon src={phone} width="40px" height="40px" />
              <Text>+48 509-480-983</Text>
              <div style={{ margin: "15px" }}></div>
              <SVGIcon src={envelope} width="40px" height="40px" />
              <Text>radca@kancelaria-prokop-majchrzak.com</Text>
            </NavSide>
          </address>
          <NavSide>
            <NavLink to="services" smooth={true} offset={-50}>
              {intl.formatMessage({ id: "services" })}
            </NavLink>
            <NavLink to="contact" smooth={true} offset={50}>
              {intl.formatMessage({ id: "contact" })}
            </NavLink>
            <LangLink to="/pl">PL</LangLink>/<LangLink to="/en">EN</LangLink>
          </NavSide>
        </NavContainer>
      </Container>
    </NavWrapper>
  );
};

export default Navigation;
